import {
  ArrowRightCircleIcon,
  CheckCircleIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "@roadflex/react-components-www";
import { RoadFlexFeatures } from "@roadflex/types";
import Image from "next/image";
import { useRouter } from "next/router";
import Script from "next/script";
import {
  AssignAndCloselyTrack,
  AutomatePaymentApprovals,
  CloseBooks,
  DigitalReceipts,
  FullVisibilityEveryDollar,
  SetRulesAndLimits,
} from "../animations";

interface AlternateComponentProps {
  title1?: string;
  subTitle1?: string;
  features1: RoadFlexFeatures[];
  partner?: string;
  isApplyNow?: boolean;
  isScheduleDemo?: boolean;
  color?: "black" | "white" | "brown" | null;
  backgroundColor?:
    | "darkblue"
    | "white"
    | "stary-background"
    | "panel-background"
    | null;
  icon?: "CheckIcon" | "CheckCircleIcon" | null;
}

export default function AlternateComponent(props: AlternateComponentProps) {
  const router = useRouter();
  const redirectTo = (page) => {
    router.push({
      pathname: `/${page}`,
    });
  };

  const {
    title1,
    subTitle1,
    features1,
    partner = "",
    isApplyNow = false,
    isScheduleDemo = false,
    color = "brown",
    backgroundColor = "white",
    icon = "CheckIcon",
  } = props;

  const goToContactUsPage = (partnerName) => {
    if (partnerName) {
      router.push({
        pathname: "/contact-us",
        query: { partner: partnerName },
      });
      if (partner === "fleetup") {
        <Script
          type="text/javascript"
          src="https://fleetup.com/wp-content/uploads/2024/06/functionwebToLead.js"
        ></Script>;
      }
    } else {
      router.push({
        pathname: "/contact-us",
      });
    }
  };

  const goToApplicationPage = (partnerName) => {
    if (partnerName) {
      router.push({
        pathname: "/signup",
        query: { partner: partnerName },
      });
    } else {
      router.push({
        pathname: "/signup",
      });
    }
  };

  let textColor = "text-brown-900";
  let headingColor = "text-brown-900";
  let titleColor = "text-brown-900";
  let bgColor = "bg-white";
  let buttonVariant = ButtonVariant.Orange;

  if (backgroundColor === "darkblue") {
    bgColor = "bg-darkblue";
    titleColor = "text-orange-500";
    textColor = "text-white";
    headingColor = "text-white";
  } else if (backgroundColor === "stary-background") {
    bgColor = "bg-cover bg-stary-background";
    headingColor = "text-brown-200";
    textColor = "text-brown-200";
  } else if (backgroundColor === "panel-background") {
    bgColor = "bg-cover bg-panel-background";
    textColor = "text-white";
    headingColor = "text-white";
  }

  if (color === "white") {
    textColor = "text-white";
    headingColor = "text-white";
  } else if (color === "black") {
    textColor = "text-black-900";
    headingColor = "text-black-900";
  }

  if (partner === "fleetup" || partner === "linxup") {
    titleColor = "text-fleetupBlue";
    textColor = "text-black-900";
    headingColor = "text-fleetupBlue";
    buttonVariant = ButtonVariant.FleetUpYellow;
  }

  let iconComponent = (
    <CheckIcon className="w-7 h-7 mr-3 min-w-[30px] min-h-[30px] stroke-[3px] text-green-800"></CheckIcon>
  );
  if (icon === "CheckCircleIcon") {
    iconComponent = (
      <CheckCircleIcon className="mr-3 text-orange-500 w-7 h-7 md:w-7 md:h-7 min-w-[30px] min-h-[30px] stroke-[3px]"></CheckCircleIcon>
    );
  }

  return (
    <div className={`w-full h-full ${bgColor}`}>
      <div
        className={`flex items-center justify-center w-full ${textColor} my-14 md:my-32`}
      >
        <div className="container w-full">
          {title1 && (
            <div className="mb-14">
              <div
                className={`mb-8 text-3xl font-bold text-center md:text-4xl 3xl:text-5xl ${titleColor}`}
              >
                {title1}
              </div>
              <div className="my-4 font-normal text-center lg:text-lg 3xl:text-xl">
                {subTitle1}
              </div>
            </div>
          )}
          <div
            className={`relative flex flex-col w-full ${
              title1 ? "mt-14 md:mt-24" : ""
            }`}
          >
            <div className="container relative flex flex-col items-center justify-center md:flex-row">
              <div className="flex flex-col w-full gap-y-10 md:gap-y-16 xl:gap-y-32 place-items-center">
                {features1.length > 0 &&
                  features1.map((feature: RoadFlexFeatures, idx: number) => (
                    <div key={idx} className="w-full">
                      {idx % 2 === 0 && (
                        <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                          <div className="md:w-[50%] order-2 lg:order-1 mt-2 lg:mt-0">
                            <div className="max-w-[400px] xl:max-w-[460px]">
                              <div>
                                <div
                                  className={`mb-4 text-xl font-bold text-center md:mb-8 md:text-3xl md:text-left ${headingColor}`}
                                >
                                  {feature.heading}
                                </div>
                                <div className="block mt-2 mb-8 lg:hidden">
                                  <div className="static">
                                    <div className="relative">
                                      <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
                                      <div className="m-[2px]">
                                        {!`${feature.imageLink}`.includes(
                                          "animation",
                                        ) && (
                                          <Image
                                            layout="responsive"
                                            width={750}
                                            height={500}
                                            objectFit="contain"
                                            priority={true}
                                            className="relative"
                                            src={`${feature.imageLink}`}
                                            alt="RoadFlex Image"
                                          ></Image>
                                        )}
                                        {(`${feature.imageLink}` ===
                                          "animation-full-visibility" ||
                                          feature?.heading ===
                                            "See where each dollar is spent on") && (
                                          <FullVisibilityEveryDollar />
                                        )}
                                        {`${feature.imageLink}` ===
                                          "animation-assign-closely-track" && (
                                          <AssignAndCloselyTrack />
                                        )}
                                        {`${feature.imageLink}` ===
                                          "animation-automate-payment-approvals" && (
                                          <AutomatePaymentApprovals />
                                        )}
                                        {(`${feature.imageLink}` ===
                                          "animation-set-rules-and-limits" ||
                                          feature?.heading ===
                                            "Set spending limits and controls") && (
                                          <SetRulesAndLimits />
                                        )}
                                        {feature?.heading ===
                                          "Receive digital receipts for every purchase" && (
                                          <DigitalReceipts />
                                        )}
                                        {feature.imageLink ===
                                          "animation-close-books" && (
                                          <CloseBooks />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="text-base text-center lg:text-left md:text-lg">
                                  {feature.text1}
                                </div>
                                <div className="flex flex-col gap-3 text-base md:text-base">
                                  {feature.bulletpoint.length > 0 &&
                                    feature.bulletpoint.map(
                                      (bp: string, idx2: number) => (
                                        <div
                                          key={idx2}
                                          className="flex flex-row items-start"
                                        >
                                          {iconComponent}
                                          <div className="ml-4 text-base md:text-lg">
                                            {bp}
                                          </div>
                                        </div>
                                      ),
                                    )}
                                </div>
                                {feature.redirectLink && (
                                  <Button
                                    variant={ButtonVariant.Transparent}
                                    className="!pl-0 mt-4 mx-auto md:ml-0 animate-pulse"
                                    onClick={() => {
                                      redirectTo(feature.redirectLink);
                                    }}
                                  >
                                    Learn More{" "}
                                    <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="hidden lg:block relative md:w-[50%] lg:order-2 w-[300px]">
                            <div className="static">
                              <div className="relative">
                                <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
                                <div className="m-[2px]">
                                  {!`${feature.imageLink}`.includes(
                                    "animation",
                                  ) && (
                                    <Image
                                      layout="responsive"
                                      width={750}
                                      height={500}
                                      objectFit="contain"
                                      priority={true}
                                      className="relative"
                                      src={`${feature.imageLink}`}
                                      alt="RoadFlex Image"
                                    ></Image>
                                  )}
                                  {(`${feature.imageLink}` ===
                                    "animation-full-visibility" ||
                                    feature?.heading ===
                                      "See where each dollar is spent on") && (
                                    <FullVisibilityEveryDollar />
                                  )}
                                  {`${feature.imageLink}` ===
                                    "animation-assign-closely-track" && (
                                    <AssignAndCloselyTrack />
                                  )}
                                  {`${feature.imageLink}` ===
                                    "animation-automate-payment-approvals" && (
                                    <AutomatePaymentApprovals />
                                  )}
                                  {(`${feature.imageLink}` ===
                                    "animation-set-rules-and-limits" ||
                                    feature?.heading ===
                                      "Set spending limits and controls") && (
                                    <SetRulesAndLimits />
                                  )}
                                  {feature?.heading ===
                                    "Receive digital receipts for every purchase" && (
                                    <DigitalReceipts />
                                  )}
                                  {feature.imageLink ===
                                    "animation-close-books" && <CloseBooks />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {idx % 2 === 1 && (
                        <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                          <div className="md:w-[50%] order-2 mt-2 lg:mt-0">
                            <div className="max-w-[400px] xl:max-w-[480px]">
                              <div>
                                <div
                                  className={`mb-4 text-xl font-bold text-center md:mb-8 md:text-3xl md:text-left ${headingColor}`}
                                >
                                  {feature.heading}
                                </div>
                                <div className="block mt-2 mb-8 lg:hidden">
                                  <div className="static">
                                    <div className="relative">
                                      <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
                                      <div className="m-[2px]">
                                        {!`${feature.imageLink}`.includes(
                                          "animation",
                                        ) && (
                                          <Image
                                            layout="responsive"
                                            width={750}
                                            height={500}
                                            objectFit="contain"
                                            priority={true}
                                            className="relative"
                                            src={`${feature.imageLink}`}
                                            alt="RoadFlex Image"
                                          ></Image>
                                        )}
                                        {(`${feature.imageLink}` ===
                                          "animation-full-visibility" ||
                                          feature?.heading ===
                                            "See where each dollar is spent on") && (
                                          <FullVisibilityEveryDollar />
                                        )}
                                        {`${feature.imageLink}` ===
                                          "animation-assign-closely-track" && (
                                          <AssignAndCloselyTrack />
                                        )}
                                        {`${feature.imageLink}` ===
                                          "animation-automate-payment-approvals" && (
                                          <AutomatePaymentApprovals />
                                        )}
                                        {(`${feature.imageLink}` ===
                                          "animation-set-rules-and-limits" ||
                                          feature?.heading ===
                                            "Set spending limits and controls") && (
                                          <SetRulesAndLimits />
                                        )}
                                        {feature?.heading ===
                                          "Receive digital receipts for every purchase" && (
                                          <DigitalReceipts />
                                        )}
                                        {feature.imageLink ===
                                          "animation-close-books" && (
                                          <CloseBooks />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-base text-center md:text-lg lg:text-left">
                                  {feature.text1}
                                </div>
                                <div className="flex flex-col gap-3 text-base md:text-base">
                                  {feature.bulletpoint.length > 0 &&
                                    feature.bulletpoint.map(
                                      (bp: string, idx2: number) => (
                                        <div
                                          key={idx2}
                                          className="flex flex-row items-start"
                                        >
                                          {iconComponent}
                                          <div className="ml-4 text-base md:text-lg">
                                            {bp}
                                          </div>
                                        </div>
                                      ),
                                    )}
                                </div>
                                {feature.redirectLink && (
                                  <Button
                                    variant={ButtonVariant.Transparent}
                                    className="!pl-0 mt-4 mx-auto md:ml-0 animate-pulse"
                                    onClick={() => {
                                      redirectTo(feature.redirectLink);
                                    }}
                                  >
                                    Learn More{" "}
                                    <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="hidden lg:block relative md:w-[50%] order-1 w-[300px]">
                            <div className="static">
                              <div className="relative">
                                <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
                                <div className="m-[2px]">
                                  {!`${feature.imageLink}`.includes(
                                    "animation",
                                  ) && (
                                    <Image
                                      layout="responsive"
                                      width={750}
                                      height={500}
                                      objectFit="contain"
                                      priority={true}
                                      className="relative"
                                      src={`${feature.imageLink}`}
                                      alt="RoadFlex Image"
                                    ></Image>
                                  )}
                                  {(`${feature.imageLink}` ===
                                    "animation-full-visibility" ||
                                    feature?.heading ===
                                      "See where each dollar is spent on") && (
                                    <FullVisibilityEveryDollar />
                                  )}
                                  {`${feature.imageLink}` ===
                                    "animation-assign-closely-track" && (
                                    <AssignAndCloselyTrack />
                                  )}
                                  {`${feature.imageLink}` ===
                                    "animation-automate-payment-approvals" && (
                                    <AutomatePaymentApprovals />
                                  )}
                                  {(`${feature.imageLink}` ===
                                    "animation-set-rules-and-limits" ||
                                    feature?.heading ===
                                      "Set spending limits and controls") && (
                                    <SetRulesAndLimits />
                                  )}
                                  {feature?.heading ===
                                    "Receive digital receipts for every purchase" && (
                                    <DigitalReceipts />
                                  )}
                                  {feature.imageLink ===
                                    "animation-close-books" && <CloseBooks />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {isScheduleDemo && (
            <div className="flex justify-center mt-14 md:mt-32">
              <Button
                type={ButtonType.Submit}
                variant={buttonVariant}
                size={ButtonSize.Large}
                onClick={() => goToContactUsPage(partner)}
                className="!px-4 sm:!px-20 font-medium md:w-fit"
              >
                Schedule a Demo
              </Button>
            </div>
          )}
          {isApplyNow && (
            <div className="flex justify-center mt-14 md:mt-32">
              <Button
                variant={buttonVariant}
                onClick={() => goToApplicationPage(partner)}
                className="!px-4 sm:!px-8 font-medium"
                size={ButtonSize.Large}
              >
                Apply Now
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
